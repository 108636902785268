/* .new-arr .slick-slide{
    margin-right: 5px;
} */

.testimonials .profile .card-title {
    font-size: 20px;
}

/* socialk */
.social img{
    height: 20px;
}
.soc-foot{
    display: flex;
    align-items: center;
}
.social{
    display: flex;
    justify-content: center;
}
.social a{
    padding:5px;
}
@keyframes fadein{
    0% {opacity: 0; 
        top:-55px; }
    100% { opacity: 1; top:0; }
}

@-webkit-keyframes fadein{
    0% {opacity: 0;  top:-50px; }
    100% { opacity: 1; top:0; }
}

/* font */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
html * {
    font-family: 'Roboto', sans-serif;
}


.foot-map img{
    width: 100%;
    height: 335px;
    object-fit: cover;
}
.mapframe{
    position: relative;
    z-index: 1;
}
.maplink{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}

/* sticky nav */
.headersicky {
    position: fixed;
    width: 100%;
    z-index: 1019;
    background-color: white;
    border-bottom: 1px solid #dfdbdb;
    -webkit-animation: 0.4s fadein ease-in;
    animation: 0.4s fadein ease-in;
    
}
/* Shrink */
.hvr-shrink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  .vertupdate{
      background-color: #EA413B;
      padding:8px 0;
      margin-bottom:3%
  }

  .vertupdate h5 span{
      border-bottom: .5px solid hsla(0,0%,100%,.83);
      padding-bottom: 2px;
      color: white;
      text-align: center;
  }

  .vertupdate h5{
    text-align: center;
    font-size: 15px;
}

/* REVAMP */

body{
    overflow-x: hidden!important;
}
.foot-contact p{
    font-size: 13px;
}
#pdflink img{
    height: 30px;
    margin-right: 10px;
}
.reredsec a:hover{
    color: white;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none!important;
}

input[type=number] {
    -moz-appearance:textfield!important;
}


input,textarea{
    -moz-appearance: none!important;
-webkit-appearance: none!important;
border-width: 1px;
border: 1px solid #cccbcb;
padding-left: 5px;

/* border-color: #454545; */
}

select{
    cursor: pointer;
    -webkit-appearance: none;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQ…gSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==);
    background-position: right center;
    background-repeat: no-repeat;
    padding-right: 1.5em;
    border: 1px solid #cccbcb;

}

.featured_main .control-prev{
    background: url('../img/next-slide left.png') no-repeat!important;
    background-size: contain!important;
    top: 50%!important;
    bottom: unset!important;
    left: 10px!important;
    z-index: 1500!important;
}



.featured_main  .control-next{
    background: url('../img/next-slide.png') no-repeat!important;
    background-size: contain!important;
    top: 50%!important;
    bottom: unset!important;
    right: 10px!important;
    z-index: 1500!important;
}

#about h3{
    font-size: 25px;
    font-weight: 700;
}

.retick h5 span{
    border-bottom: 0.5px solid #ffffffd4; padding-bottom: 2px;
}

.headquote:hover{
    
}
.mobtrddiv2{
    display: none;
}
.foot-contact p {
    margin-bottom:0.5rem;
}
/* 
.featured_main .carousel .control-arrow{
    opacity: 1;
} */

.featured_main .carousel .control-arrow,.featured_main .carousel.carousel-slider .control-arrow{
    opacity: 0.7;
}

.featured_main .carousel .control-arrow:hover,.featured_main .carousel.carousel-slider .control-arrow:hover{
    opacity: 1;
}


.featured_main .carousel .control-next.control-arrow:before {
    border-left: none;
}

.featured_main .carousel .control-prev.control-arrow:before {
    border-right:none;
}

.featured_main .carousel .control-arrow:before, .featured_main .carousel.carousel-slider .control-arrow:before {
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
}
/* .cat-desc p{margin-bottom: 0;} */
#about{
    margin-bottom: 20px;
}
.red-gal-img img:hover{
    transform: scale(1.03);
    transition: 0.25s ease;
    opacity: 0.98;
}

.subthumbs {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
    margin-top: 10px;
}

.mainthumb{
    position: relative;
    display: block;
    height: 150px;
    overflow: hidden;
    margin-bottom: 10px;
    width: 23%;
    flex: 1 1 23%;
    margin-right: 1%;
    max-width: 25%;
}
.subthumbs p {
    color: white;
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    margin: 0;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}
div.mainthumb > div{
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.78), rgba(230, 222, 222, 0)), url(https://5.imimg.com/data5/QX/XW/MY-8533797/house-interior-3d-wall-tiles-500x500.jpg);
    position: absolute;
    height: 150px;
    width: 100%;
    -moz-transition: all .5s;
    -webkit-transition: all .5s;
    transition: all .5s;
    -moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    z-index: -1;
}

/* Grow */
.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
    cursor: pointer;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);
}
.foot-contact p{
    word-break: break-word;
    color: #454545;
}
.foot-contact a{
    color: #454545;
}
.foot-contact a:hover{
    color: #EA413B;
}
.sec-footer .headdiv a{
    font-weight: 400;
    font-size: 13px;
    padding: 5px;
}
input{
    border-radius:0!important ;
}
.foot-contact h6{
    font-weight: 500;
}
#pdflink a{
    font-size: 14px;
}
header{
    min-height: 150px;
}
.majordeals .slick-slider{
    margin-bottom: 40px;
} 
.rehomehead .new-arr-div{
    margin-bottom: 400px;
}
.i4ewOd-pzNkMb-tJHJj{
    display: none!important;
}
.sec-footer{
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    align-items: center;
}
.search-res span,.mobresults span{
    color:#EA413B ;
    font-weight: 500;
}
/* QUOTe FORm */
.formvalidation{
    background-color: #EA413B;
    padding: 5px 0;
    color: white;
    z-index: 1063;
    text-align: center;
}
select:focus,input:focus,button:focus{
    outline: none!important;
    border-radius: 0!important;
}
.cc_code{
    color:#8e8e8e
}
.quote-btn{
    display: flex;
    justify-content: flex-end;
}
.quote-req textarea{
    width: 100%;
    height: 120px;
    border-radius: 0;
    -webkit-appearance: none;

}

.quote-sel-box{
    display: flex;
    justify-content: space-between;
}

.quote-sel-box select{
    -webkit-appearance: none;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==);
    background-position : right center;
    background-repeat: no-repeat;
    padding-right: 1.5em;
    border-radius: 0!important;
    padding: 5px;
    width: 49%;
    border-width: 1px;
    background-color: white;
}

.quote-heading{
    text-align: left;
}

.quote-heading h2{
    font-size: 25px;
    margin-bottom: 0;
    color: #EA413B;
}
.quote-heading p{
    margin-bottom: 0;
    color: #454545;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.name-mail,.quote-req,.quote-sel-box,.quote-heading{
    margin-bottom: 10px;
}
.name-mail{
    display: flex;
    justify-content: space-between;
}

input:focus{
    background-color: white;
}

input[type=number] {
    -moz-appearance:textfield;
}
.quote-form-bg{
    position: fixed;
    background-color: #00000096;
    z-index: 1060;
    height: 100vh;
    width: 100%;
}
#test {
	width: 200px;
	padding: 2em 0;
	background: #c14545;
	visibility: hidden;
}

.slideDown{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;	
	animation-duration: 0.6s;	
	-webkit-animation-duration: 0.6s;
	animation-timing-function: ease;	
	-webkit-animation-timing-function: ease;	
	visibility: visible !important;						
}

@keyframes slideDown {
	0% {
		transform: translateY(-100%);
	}
	/* 50%{
		transform: translateY(8%);
	}
	65%{
		transform: translateY(6%);
	}
	80%{
		transform: translateY(4%);
	}
	95%{
		transform: translateY(2%);
	}			 */
	100% {
		transform: translateY(0%);
	}		
}

@-webkit-keyframes slideDown {
	0% {
		-webkit-transform: translateY(-100%);
	}
	/* 50%{
		-webkit-transform: translateY(8%);
	}
	65%{
		-webkit-transform: translateY(6%);
	}
	80%{
		-webkit-transform: translateY(4%);
	}
	95%{
		-webkit-transform: translateY(2%);
	}			 */
	100% {
		-webkit-transform: translateY(0%);
	}	
}
.quote-form{
    visibility: hidden;
    position: fixed;
    left: 7.5%;
    /* -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0); */
    z-index: 1061;
    top: 12%;
    background-color: white;
    padding: 3% 4%;
    width: 85%;
}
.qoute-footer{
    background-color: #EA413B;
    height: 10px;
}
.qoute-form-sec{
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    padding: 25px 10px 0 10px;
}
.qoute-form-sec img{
    height: 20px;
    width: auto;
    object-fit: contain;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
}
.qoute-form-sec input{
    padding:5px;
    width: 33%;
    border-width: 1px;
    -webkit-appearance: none;
    border: 1px solid #cccbcb;
}
.qoute-form-sec button:hover{
    background-color: #EA413B;
    color: #fff;
}
.qoute-form-sec button{
    background-color: #fff;
    color: #EA413B;
    cursor: pointer;
    -webkit-appearance: none;
    border: 1px solid #ea413b;
    padding: 3px 30px;
    border-color: #ea413b;
}
/* Search SECtion */
.noresults{
    position: relative;
    top:10%
}
.footer-sec{
    display: flex;
    justify-content: space-between;
    padding: 0px;
}
.foot-map{
    width: 52%;
    height: 335px;
    overflow: hidden;
}

.foot-contact{
    width: 46%;
    text-align: left;
    padding:10px 0
}
  .mobmenu input{
    -webkit-appearance: none;
    border: 0;
    border-bottom: 1px solid #EA413B;
  }
  .mobmenu input:focus{
    -webkit-appearance: none;
    border: 0;
    border-bottom: 1px solid #EA413B;
    outline: none;
  }
.search-res {
    position: absolute;
    right: 110%;
    width: 500px;
    top: 23px;
    background-color: white;
    z-index: 1059;
    border-right: 0.5px solid #c9c9c9;
    border-bottom: 0.5px solid #c9c9c9;
    border-left: 0.5px solid #c9c9c9;
}
.search-res ul a{
    color: #454545;
    font-size: 14px;
}
.search-res ul a:hover{
    color:#000;
    font-weight: 500;
}
.search-res ul,.mobresults ul{
    padding: 0;
    list-style: none;
}
.search-res ul li,.mobresults li{
    padding: 2px 0;
    text-align: left;
}
.search-res ul{
    padding-top: 10px;
    margin-bottom: 0
}
.noresult li{font-style: italic;}
.search-res ul li{
    padding-left: 5px;
}

/* .search-section input {
    width: 75px;
    
 }
 .search-section input:focus {
    width: 200px;
 } */

 .search-section .inputlength{
    width: 500px;
    visibility: unset;
 }
 
.search-section input{
    padding-left: 5px;
}
.search-section{
    position: relative;
}
.search-section img{
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
}
.search-section input{
    height: 25px;
    width: 0;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
    visibility: hidden;
    /* opacity: 0; */
}
.search-section input:focus{
    outline:none;
    outline-offset:0
}

.search-section .search-bar{
    position: absolute;
    right: 110%;
}
/* Brand s in Trading page */
.fb-brand{
    display: flex;
    justify-content: space-between;
    margin:5% 0
}
.brand-icons-div{
    display: flex;
    flex-wrap: wrap;
    width: 49%;
    align-items: flex-start;
}
.fb-embed{
    width: 49%;
    /* height: 250px; */
    overflow: hidden;
}
.fb-embed iframe{
    width: 100!important;
}
._2p3a{
    width: 100%!important;
}

.brand-icons-div .cat-icons{
    width: 19%;
    margin-right: 1%;
}

.brand-icons-div .cat-icons img{
    width: 60px;
    object-fit: contain;
    height: 60px;
}
/* CAT Icons in Trading page */
.cat-icons-name{
    font-size: 12px;
    padding:5px
}
.cat-icons-sec{
    display: flex;
    flex-wrap: wrap;
}

.cat-icons img{
    height: 35px;
}

.cat-icons:hover .set img{
    transform: scale(1.05);
}

.cat-icons:hover .cat-icons-name{
    color: #727272;
}

.set img{
    transition: 0.25s ease;

}

.cat-icons{
    width: 10%;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: flex-start;
    margin: 10px 0;
    flex: 1 1 auto;
    color: #454545;
    transition: 0.25s ease;
}

.quote-trade{
    margin-left: -6%;
    width: 112%;
    display: flex;
    left: 0;
    background-color: #EA413B;
    padding: 20px 22%;
    align-items: center;
    justify-content: space-between;
    font-size:12px ;
}


.quote-trade div{
    padding: 5px 20px;
    background-color: #fff;
    color: #EA413B;
    cursor: pointer;
}

.quote-trade p{margin-bottom: 0;color: white;font-size: 17px;}

.cat-icons-div{
    margin: 5% 0 5% 0;
}

.footer-div{
    background-color: #F3F3F3;
    padding: 0px 0;
}

.rehomehead .set img{
    height: 60px;
    width: 60px;
    object-fit: contain;
}

.rehomehead .set,.cat-icons .set{
    display: flex;
    justify-content: center;
}

.majordeals{
    padding:20px 0;
    
}
.majordeals .slick-dots{
    bottom: -40px;
}

.majordeals .slick-dots li button:before,.red-cat-slides .slick-dots li button:before{
    font-size: 8px;
}
.majordeals .slick-dots li button:focus:before,.red-cat-slides .slick-dots li button:focus:before, .majordeals .slick-dots li button:hover:before,.red-cat-slides .slick-dots li button:hover:before {
    opacity: .25;
}

.majordeals .slick-dots li.slick-active button:before ,.red-cat-slides  .slick-dots li.slick-active button:before{
    opacity: .75 !important;
    color: #000;
}
.red-major{
    margin: 5% 0;
}
/* Galeery */
.red-gal{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2%;
}
#tick li{
    background-color: unset!important;
    list-style: none;
    text-align: center;
}
.red-cat-slides .slide{
    margin:25px;
    padding:50px;
    display:flex;
    justify-content: center;
}
.red-cat-slides .set>img{
    display: flex;
    margin: 0 auto;
    height: 60px;
    width: 60px;
    object-fit: contain;
}

.red-cat-slides .slides{
    margin: 50px 35px 70px 35px;
}

.cat_pag .slick-dots{
    bottom: -30px;
}

.rednews{
    min-height: 150px;
}
.red-gallery{
    margin: 0 10px;
}
.red-gal-img{
    width: 19%;
    display: flex;
    justify-content: center;
    margin: 0px 0 10px 10px;
    background-color: rgb(8, 8, 8);
    position: relative;
}
.red-gal-img img{
    height: 220px;
    width: 100%;
    object-fit: cover;
    opacity: 0.95;
}
.red-gal-pop-img img{
    height: 80vh;
}
.rednews-head{
    margin-bottom: .5rem;
    font-family: inherit;
    line-height: 1.2;
    color: #EA413B;
    font-size: 24px;
    margin-top: 20px;
    font-weight: 500;
}
.rednews-head {display:inline-block}
.rednews-head:first-letter {text-transform: uppercase}

.footer-div h3{
    color: #EA413B;
    font-weight: 500;
    font-size: 25px;
}

.cat_pag .rednews-div {
    margin: 5px 0px 20px 0px;
}

.rednews-div{
    margin:20px 0
}
.rednews-div a{
    color: #454545;
    font-size:18px;
}

.rednews-div h5{
    color: #454545;
    font-size:18px;
}

.rednews-div a:hover{
    color: #ea413b;
}

.redupdate{
    background-color: #ea413b;
    color: white;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
}

.rev-division{
    overflow-x: hidden;
}
#ticker ul{
    margin-bottom: 0;
}
#ticker h5{
    margin-bottom: 4px;
}
/* 
.redupdate #ticker a:hover{
    text-decoration: underline;
} */

.redupdate #ticker a{
    color:white;
    /* text-decoration: underline; */

}
.slick-next,.slick-prev{
    display: none!important;
}

.slick-prev{
    display: none;
}
.redupdate .slick-slider,.redupdate .slick-list{
    position: relative;
}

.redupdate h5{
    font-size: 15px;
    color:white
}
.relogo{
    display: flex;
    justify-content: flex-start;
}
.divnhead{
    color: #ea413b;
    margin-bottom: 2%;

}
a:hover{
    text-decoration: none!important;
}

.headdiv a:hover{
    color: #ea413b;
}

.new-arrivals::-webkit-scrollbar {
    height: 0px;
  }

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.mob-ham{
    display: none;
}

.relogo img{
    height: 35px;
}

.retopsec{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
    align-items: center;
}

.reright{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.headquote{
    padding: 5px 15px;
    transition: 0.2s ease-in;
    background-color: #EA413B;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.headdiv a{
    padding: 10px;
    color: #454545;
    font-weight: 500;
}

/* RED sec */
 .reredsec{
    flex-wrap: wrap;
    background-color: #c61f19cc;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1010;
    justify-content:center ;
 }

 .reredsec a:hover{
     color: #c6c4c4;
     text-decoration: none;

 }

 .reredsec a{
     color: white;
     padding: 15px 5px;
    font-size: 12px;
    transition: 0.25s ease;
    flex: 1 1 auto;
 }


.rehomehead .reheader{
    margin-bottom: 520px;
}

.rehomehead,.reheader,.reredsec{
    position: relative;
}

/* CAROUSAL */
.carousal-div{
    position: relative;
}

.carousal-text{
    position: absolute;
    color: #EA413B;
    z-index: 3;
    top: 45%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center ;
}

.carousal-text p{
    margin-bottom: 0;
}
    
.carousal-text p:first-child{
    font-size: 45px;
    font-weight: 500;
    width: fit-content;
    text-transform: uppercase;

}

.carousal-text p:nth-child(2){
    font-size: 30px;
    font-weight: 500;
    background-color: #c61f19cc;
    color: white ;
    width: fit-content;
    padding:10px 20px
}

.pgallery{
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 70px;
    height: 550px;
    margin-bottom: -50px;
}

.pgallery .slide img{
    height: 550px;
    object-fit: cover;
}

/* STATS SEC */

.statsec{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    justify-content: center;
    width: 24%;
    padding-right: 5px;
}

.statsHome{
    display: flex;
    flex-wrap: wrap;
    padding-left: 5%;
    justify-content: center;
}

.statshead{
    display: flex;
    text-align: left;
    align-items: flex-end;
    margin: 10px 0;
}

.statshead img{
    height: 45px;
}

.stathead{
    color:#EA413B;
    font-weight: 500;
    font-size: 18px;
}

.statsub{
    color: #454545;
    font-size: 13px;
}

.staticon{
    margin-right: 10px;
}

/* TABS */

.retab-div{
    display: flex;
    height: 270px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(173,171,173,1);
-moz-box-shadow: 0px 0px 7px 0px rgba(173,171,173,1);
box-shadow: 0px 0px 7px 0px rgba(173,171,173,1);
    margin:3% 5% 5% 5%;
    align-items: center;
    padding: 20px 20px 20px 0px;
    text-align: left;
}

.re-tab-img{
    width: 35%;
}

.re-tab-img img{
    height: 270px;
    object-fit: cover;
    width: 100%;
}

.re-tab-main{
    width: 60%;
    padding-left: 5%;
    padding-right: 5%;
}

.read-more{
    padding: 5px 15px;
    background-color: #EA413B;
    color: white;
    cursor: pointer;
    font-size: 12px;;
    width: max-content;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(173,171,173,1);
-moz-box-shadow: 0px 1px 2px 0px rgba(173,171,173,1);
box-shadow: 0px 1px 2px 0px rgba(173,171,173,1);
}

.read-more a,.read-more a:hover{
    color: #fff;
}

.re-tab-head{
    color:#EA413B;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 5px;
}

.re-tab-sub{
    color:#454545;
    font-size: 13px;
    margin-bottom: 5px;
}

/* NEW ARRIVALS */

.new-arr-div{
    background-color: #ea413b;
    height: 500px;
    position: relative;
}

.new-arrivals{
    display: flex;
    overflow-x: scroll;
    
}

.new-box{
    margin-top: 50px;
    margin-bottom: 20px;
    height: 240px;
    background-color: white;
    min-width: 180px;
    margin-right: 1%;
    -webkit-box-shadow: 0px 2px 7px 0px rgba(13, 13, 13, 0.4);
-moz-box-shadow: 0px 2px 7px 0px rgba(13, 13, 13, 0.4);
box-shadow:0px 2px 7px 0px rgba(13, 13, 13, 0.4);
}


.contain{
    padding-right:5%;
    padding-left: 5%;
}

.containmarg{
    margin:0 5%
}

.new-arr-header{
    display: flex;
    padding-top: 5%;
    font-size: 22px;
    color: white;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.new-arr-header p{
    width: 20%;
    text-align: left;
    margin-bottom: 0;
}

.new-arr-line{
    height: 1px;
    background-color: #ffffff7d;
    width: 90%;
}

#imagecontainer {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-position: top center;
}

.new-text-sec{
    padding-left: 10px;
    text-align: left;
    margin-top: 5px;
}

.new-tit,.new-enq{
    color: #ea413b;
    font-weight: 500;
}

.new-tit{
    font-size: 13px;
}

.new-enq{
    font-size: 12px;
    border-bottom: 1px dotted #ea413b;
    width: max-content;
    margin-top: 5px;
    cursor: pointer;
}

.new-type{
    font-size: 11px;
    color: #454545;
    line-height: 10px;

}


/* TESTIMONIALS */
.new-test-header{
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.new-test-header{
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
}

.testimonials {
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    padding: 25px 30px;
    position: relative;
    margin: 0 10%;
    bottom: -50px;
}

.testimonials .profile .card-title{
    color: #141744;
}

.testimonials .card-title {
    font-size: 25px;
    width: 100%;
    text-align: left;
    color: #ea413b;
}

.testimonial_div {
    width: 100%;
}

.testimonial_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4% 4% 0 0%;
    text-align: left;
}

.testimonial_header_div1 {
    width: 100%;
    display: flex;
}

.testimonial_header_div1 div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-align: left;
}

.testimonial_header_div1 img {
    border-radius: 50%;
    object-fit: cover;
    height: 75px;
    width: 75px;
}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    margin-left: 5%;
}

.quote {
    position: absolute;
    right: 5%;
    z-index: 1;
}

.testimonial_desc p{
    margin-bottom: 0;
}

.testimonial_desc {
    height: 120px;
    overflow-y: auto;
    margin: 2% 2% 0 0%;
    padding-right: 10px;
    text-align: left;
    color: #454545;
}

.testimonials .card-text{
    color: #454545;
}

.testimonial_footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto;
    align-items: center;
}

.testimonial_footer img {
    height: 50px;
}

.testimonial_arrow {
    display: flex;
    width: 7%;
    justify-content: space-between;
    margin: 0 5px;
}

.testimonial_footer img {
    height: 50px;
}

.testimonial_arrow img{
    height: 25px;
}

/* VIDEOS */
#overlayimg{
    position: absolute;
    top: -1px;
    width: 101%;
    z-index: 1005;
    right: 0;
}

.video-img-tint{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1004;
    height: 100%;
    left: 0;
}

#overlayplay{
    position: absolute;
    top: 40%;
    z-index: 1006;
    left: 45%;
    display: flex;
    cursor: pointer;
    align-items: center;
}

#overlayplay img{
    height: 80px;

}

#overlayplay p{
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 25px;
}

.tradearr .new-arrivals{
    flex-wrap: wrap;
    justify-content: space-between;
}

.tradearr .new-box {
    margin-bottom: 10px;
    height: 220px;
    background-color: white;
    width: 49%;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(13, 13, 13, 0.4);
    box-shadow: 0px 2px 2px 0px rgba(13, 13, 13, 0.4);
    margin-top: 0;
    border-top: 1px solid #8080802b;
}

.tradearr #imagecontainer {
    width: 100%;
    height: 150px;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
}

.tradearr .new-arr{
    width: 100%;
}


.tradearr .new-arr-line {
    height: 0.7px;
    background-color: #ea413b96;
    width: auto;
    flex: 1 1;
}

.tradearr .new-arr-header p {
    width: auto;
    text-align: left;
    margin-bottom: 0;
    font-size: 20px;
    margin-right: 10px;
}

.tradearr .new-arr-header {
    display: -ms-flexbox;
    display: flex;
    padding-top: 0%;
    PADDING-BOTTOM: 5%;
    font-size: 17px;
    color: #ea413b;
}

.divn-1{
    width: 62%;
}

.divn-2{
    width: 34%;
}
.neVct-IlgHgd{
    display: none!important;
}
/* RESPONSIVE */
/* 2K */
@media only screen and (min-width: 1400px) {
    .contain {
        padding-right: 10%;
        padding-left: 10%;
    }
    .fb-brand{
        padding-right: 0%;
        padding-left: 0%;
    }
    .quote-trade {
        margin-left: -22%;
        width: 145%;
        padding: 20px 40%;
    }
    
    .new-arr-div .new-arr .slick-initialized .slick-slide {
        display: block;
    }
}

@media only screen and (max-width: 1100px) {

}
/* PHONE */
@media only screen and (max-width: 330px) {
    .new-arrivals .new-box{
        height: 285px;
    }
    .new-box #imagecontainer{
        height: 190px;
    }
    .stattext .statsub {
        color: #454545;
        font-size: 9px;
    }
    .statsec .staticon{
        padding-bottom: 2px;
    }
    .footer-sec .foot-map{
        height:220px
    }
    .rev-division .vertupdate h5 span{
        font-size: 10px;
    }
    .foot-contact h6 {
        font-weight: 500;
        font-size: 13px;
    }
    .foot-contact p {
        font-size: 11px;
    }
    .red-gallery .red-gal-img img {
        height: 150px;
    }
    .red-gal-img {
        margin: 0px 0 10px 7px;
    }
    .rev-division .redupdate {
        padding: 10px 0;
    }
    #about .quote-trade p {
    
        font-size: 15px;
    }
}
@media only screen and (min-width: 767px) {
    /* .sec-footer .headdiv,.sec-footer .relogo{
        width: 33%;
    } */
    .webtrddiv2 .tradearr .new-box {
        height: 245px;
        min-width: auto;
    }
    .webtrddiv2 .new-box{
        width:48.5%!important
    }
    .webtrddiv2 .tradearr #imagecontainer {
        height: 160px;
        background-size: cover;
    }
    .new-box{
        width: 180px!important;
    }
    .foot-map iframe{
        width: 100%;
    }
    .headersicky{
        height: 55px!important;
    }
    .new-arr .slick-initialized .slick-slide {
        display: flex;
        justify-content: flex-start;
    }
}

@media only screen and(max-width:500px){
    .brand-icons-div .cat-icons .set img{
        width: 50px;
        height: 45px;
    }
     .testimonials .profile .card-title {
        font-size: 18px;
    }
    .carousal-text p:first-child{
        font-size:35px
    }
    .carousal-text p:nth-child(2){
        font-size:20px
    }
}

@media only screen and (max-width: 767px) {
    .brand-icons-div .cat-icons .set img{
        width: 50px;
        height: 45px;
    }
    .testimonials .profile .card-title {
        font-size: 18px;
    }
    .soc-foot{
        flex-direction: column;
    }
    .social a {
        padding:5px;
    }
    .social{
        justify-content: center;
    }
    .headersicky{
        height: 50px!important;
    }
    .subthumbs{
        margin-bottom: 0;
    }
    .red-cat-slides .slick-initialized .slick-slide{
        display: unset;
        text-align: center;
    }
    .rehomehead .set img{
        height: 60px;
        width: 80px;
        object-fit: contain;
    }
    .slick-slide img {
        display: unset!important;
    }
    .cat_pag .slick-dots{
        bottom: -55px;
    }
    .mainthumb{
        max-width: unset;
        flex:1 1 auto
    }
    .vertupdate h5 span{
        font-size: 12px;
    }
    .foot-contact h6{
        font-weight: 700;
    }
    .footer-div h3{
        font-weight: 700;
    }
  
    .rednews-head{
        font-weight: 700;
    }
    .tradearr .new-tit,.tradearr .new-enq{
        font-size: 14px!important;
    }
    .mobtrddiv2{
        display: block;
    }
    .new-tit, .new-enq {
        color: #ea413b;
        font-weight: 500;
        font-size: 15px!important;
        font-weight: 700;
    }
.read-more {
    padding: 5px;
    color: white;
    font-size: 17px;
    width: 50%;
    text-align: center;
}
    .webtrddiv2{
        display: none!important;
    }
    .mainthumb{
        margin-bottom: 20px;
        width: 100%;
    }
    .statshead img {
        height: 35px;
        width: 100%;
        object-fit: contain;
    }
    .statshead{
        width: 100%;
    }
    .sec-footer{
        text-align: center;
    }
    .red-major{
        margin: 5% 0 0% 0;
    }
    .quote-trade p {
        margin-bottom: 15px;
        color: white;
        font-size: 17px;
    }
    .quote-trade{
        flex-direction: column;
    }
    .sec-footer, .headdiv{
        flex-direction: column;
        display: flex;
        margin: 5px 0;
    }
    .quote-form{
        padding: 7% 4%;
        top:5%;
        max-height: 90vh;
        overflow: scroll;
    }
    .redupdate{
        padding: 15px 0;
        height: unset;
    }
    .rednews-head{
        font-size: 1.5rem;
    }
    .App-header{
        margin-bottom: 0%;
    }
    .footer-sec{
        flex-wrap: wrap;
    }
    .foot-map img {
        height: unset;
    }
    .foot-map {
        width: 100%;
        height: 240px;
        overflow: hidden;
        margin-bottom: 0px;
        padding-bottom: 20px;
        border-right: none;
    }
    .foot-contact {
        width: 100%;
        padding: 0px 15px 10px 15px;
    }
    .quote-req textarea {
        width: 100%;
        height: 120px;
    }
    .name-mail{
        flex-wrap: wrap;
    }
    .qoute-form-sec input {
        width: 100%;
        padding: 1px;
        margin-bottom: 10px;
        border-radius: 0px!important;
    }
    /* .rehomehead .set img {
        height: 25px;
    } */
    .fb-brand{
        flex-wrap: wrap;
    }
    .brand-icons-div{
        width: 100%;
    }
    .fb-embed {
        width: 100%;
        height: 200px;
    }
    .red-gal-pop-img img {
        height: 48vh;
        width: 100%;
        object-fit: contain;
    }
    .cat-icons-sec {
        flex-wrap: wrap;
    }
    .cat-icons {
        width: 49%;
        margin-bottom: 10%;
    }
    .redupdate #ticker a{
        font-size: 12px;
    }
    #gallery-popup-col{
        text-align: center;
    }
    .red-gal-pop-img{
        width: 80%;
        height: 50vh;
    }
    .red-gallery{
        margin-top: 2%;
    }
    .red-gal-img img{
        height: 170px;
    }
    #tick{
        padding-left: 0;
    }
    .redupdate .slick-slider, .redupdate .slick-list{
        top: 0;
    }
    .red-gal-img {
        width: 47%;
    }
    .rehomehead .reheader{
        margin-bottom: 190px;
    }
    .tradearr .new-arrivals {
        -ms-flex-wrap: wrap;
        flex-wrap: nowrap;
        padding-left: 2px;
    }
    .tradearr .new-box {
        margin-bottom: 10px;
        height: 280px;
        min-width: 60%;
        margin-right: 2%;
        margin-top: 5%;
    }
    .tradearr .new-arr-header p {
        width: 40%;
        font-size: 20px;
    }
    .tradearr #imagecontainer {
        width: 100%;
        height: 200px;
    }

    #about .divn-2{
        margin: 0 0px 0 5px;
    }
    .divn-2{
        width: 100%;
    margin: 0 0px 0 30px;
    }
    .divn-1 {
        width: 100%;
    }
    #overlayplay {
        top: 35%;
        left: 37%;
    }
    #overlayplay img {
        height: 50px;
    }
    #overlayplay p{
        font-size: 17px;
    }
    .App-header{
        top:0
    }
    .retab-div{
        height: auto;
        padding:0px 0px 20px 0px
    }
    .re-tab-img{
        width: auto;
    }
    .re-tab-main{
        width: auto;
        padding-left: 5%!important;
    }
    .statsHome{
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
    }
    .statsec {
        width: 50%;
        margin: 15px 0;
    }
    .stathead {
        font-size: 18px;
    }
    .stathead{
        font-weight: 700;
    }
    .staticon {
        margin-right: 10px;
        width: 20%;
        font-weight: 700;
        padding-bottom: 5px;
    }
    .statsub {
        color: #454545;
        font-size: 11px;
    }
    .pgallery .slide img{
        height: 180px;
    }
    .pgallery{
        height: 300px;
    }
    .carousal-text{
        top:25%
    }
    .reredsec{
        display: none;
    }
    .pgallery{
        margin-bottom:0 ;
    }
    .reright{
        display: none;
    }
    .retab-div{
        flex-direction: column!important;
        padding: 0px 0px 20px 0px!important;
    }
    .re-tab-head{
        margin-top: 10px;
        font-weight: 700;
    }
    .relogo img {
        height: 25px;
    }
    .carousal-text p:nth-child(2) {
        font-size: 23px;
    }
    .new-arr-header p {
        width: 80%;
    }
    /* #root{
        overflow-x: hidden;
    } */
    .new-arrivals{
        overflow-x: scroll;
        width: 106%;
    }
    .divisions-about-section .new-arrivals{
        width: 100%;
    }
    .new-box {  
        min-width: 60%;
        width: 70%;
        height: 300px;
        margin-bottom: 0;
      }
      #imagecontainer {
        width: 100%;
        height: 200px;
      }
      .testimonials{
          margin:0
      }
      .testimonial_arrow{
          width: 20%;
      }
      .testimonials .card-title{
        position: relative;
        z-index: 3;
        text-align: center;
        font-weight: 700;
      }
      .testimonials .testimonial_div .card-title{
          font-weight: unset;
      }
      .testimonial_header_div1 img {
        height: 60px;
        width: 60px;
    }
    .profile .card-text{
        font-size: 11px;
    }
    .testimonial_div {
        width: 100%;
        margin-top: 5%;
    }
    .quote {
        top: 22%;
    }
    .testimonial_desc {
        height: 120px;
        margin: 2% 0% 0 0%;
        padding-right: 0px;
    }
    .new-arr{
        margin-top: -5%;
    }
    .mob-ham{
        display: block;
    }
    .mob-ham img{
        height: 15px;
    }
    .testimonials .profile .card-title {
        font-size: 18px;
    }
}

@media only screen and (min-width: 501px) and (max-width: 767px) {
    .foot-map img{
        height: 260px;
    }
    .statsHome {
        flex-direction: row;
        margin-bottom: 10%;
    }
    .statsec {
        width: 50%;
    }
    .statshead img {
        height: 40px;
        width: unset;
    }
    /* .staticon {
        width: 10%;
    } */
    .new-arr-div {
        height: 550px;
    }
    .quote-form {
        padding: 4% 4%;
        top: 5%;
    }
    .reheader .mobmenu{
        width: 60%;
    }
    .tradearr .new-arr-header p {
        width: 25%;
    }
    .fb-embed {
        height: 250px;
    }
    .red-gal-pop-img{
        width: 60%;
        height: 50vh;
    }
    .red-gal-pop-img img{
        height: 60vh;
    }
    .re-tab-img img {
        height: 320px;
    }
    .App-header {
        max-height: 290px;
        padding: 0px;
    }
    .red-gal-img img {
        height: 280px;
    }
    .tradearr .new-box {
        margin-bottom: 10px;
        height: 280px;
        min-width: 45%;
    }
    .new-box {
        min-width: 40%;
    }
    #overlayplay {
        top: 44%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 899px) {
    .webtrddiv2 .tradearr #imagecontainer {
        height: 140px;
    }
        
    .webtrddiv2 .tradearr .new-box{
        width: 49% !important;
        height: 220px;
    }
    .new-arr-div .new-arr .new-box {
        min-width: 180px;
        width: 175px!important;
        height: 200px;
    }
    .brand-icons-div .cat-icons .set img{
        width: 50px;
        height: 45px;
    }

}
@media only screen and (min-width: 768px) and (max-width: 820px) {
    .footer-div .footer-sec .foot-map{
        height:270px ;
    }
    .footer-div .footer-sec .foot-map img {
        height: 270px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
    .footer-sec .foot-map img {
        height: 300px;
    }
    
    .footer-sec .foot-contact p{
        font-size: 8px;
    }
    
    .statsHome  .statsec{
        width: 25%;
    }
    .footer-sec  .foot-map {
        height: 300px;
    }
    .new-arr-header p{
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .foot-map img {
        height: 335px;
    }
    .new-arr .new-box {
        min-width: 165px;
        width: 165px!important;
        height: 200px;
    }
    .headquote{
        font-size: 10px;
        padding: 5px 10px;
    }
    .new-tit{
        padding-right: 3px;
    }
    #imagecontainer {
        height: 120px;
    }
    /* .reredsec{
        min-height: 40px;
    } */
    .red-gal-pop-img img {
        height: 65vh;
    max-width: 100%;
    object-fit: contain;
    }    
    .prev-next-buttonb div{
        width: 80%;
        text-align: center;
    }
    .prev-next-buttonb{
        position: relative;
    }
    .prevb{
        position: absolute;
    top: 50%;
     left: 3%;
    }
    .nextb{
        position: absolute;
        top: 50%;
         right: 3%;
    }
    .red-gal-img{
        margin:0px 0 10px 7px
    }
    .tradearr .new-box{
        height: 230px;
    }
    .foot-map {
        height: 330px;
    }
    .foot-contact h6{
        font-size: 0.8rem;
    }
    .foot-contact p{
        font-size: 12px;
    }
    
    .search-section .inputlength,.search-res{
        width: 300px;
     }
    /* .search-res,.search-section input{
        width: 330px;
    } */
    .red-gal-img img {
        height: 160px;
    }
    .new-tit {
        font-size: 11px;
    }
    .new-enq {
        font-size: 11px;
    }
    .reredsec a {
        color: white;
        padding: 15px 0px;
        font-size: 9px;
    }
    .staticon {
        margin-right: 5px;
    }
    .statsub{
        font-size: 10px;
    }
    .stathead{
        font-size: 20px  ;
    }

    .re-tab-sub {
        font-size: 11px;
    }
    .re-tab-img img,.retab-div {
        height: 240px;
    }
    .headdiv a {
        font-size: 10px;
    }
    .cat-icons img {
        height: 40px;
    }
    .cat-icons-name{
        font-size: 11px;
    }
    .testimonial_arrow{
        width: 12%;
    }
}

.bgblack{
    position: fixed;
    background: #000000b3;
    height: 100vh;
    z-index: 1020;
    width: 100%;
    opacity: 0;
    animation: fadeIn .45s ease forwards;
    animation-delay: .15s;
}

.mobmenu{
    position: fixed;
    width: 90%;
    background: white;
    right: -107%;
    height: 100vh;
    z-index: 1020;
    animation: fadeInRight1 .45s ease forwards;
    animation-delay: .15s;
    text-align: left;
    padding-top: 10px;
    padding-left: 0%;
}

.mobmenu a{
    color:#454545
}

.topdivn{
    display: flex;
    flex-direction: column;
}

.topdivn a{
    padding: 5px 0 5px 0%;
    font-size: 18px;
}

.mobclose{
    text-align: right;
    position: relative;
    left: 10px;
    top: 10px;
    display: flex;
}

.mobclose input {
    margin: 0px;
    padding-left: 20px;
}

.mobmenu div:nth-child(3){
    left: 5px;
}

.mobclose img{
    height: 20px;
    position: relative;
    left: 20px;
    bottom: -2px;
}

.mobmenu .headquote{
    width: fit-content;
    margin-top: 10px;
    font-size: 17px;
}

.mobresults{
    padding-bottom: 20px;
    padding-top: 20px;
}

.topdivn-sec{
    padding-bottom: 15px;
    border-bottom: 1px solid DARKGREY;
    margin-top: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.mobcats {
    padding:5px 0 20px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow-y: scroll;
    white-space: normal;
    height: 50vh;
    overflow-x: hidden;
}

.mobcats a{
    padding: 10px 0 10px 0%;
    font-size: 15px;
    border-bottom: 1px solid #d5d4d4;
    margin-left: 10%;
    margin-right: 10%;
}

.ytp-pause-overlay-controls-hidden .ytp-pause-overlay{
    display: none;
}


@-webkit-keyframes fadeInRight1 {
    0% {
        opacity: 0;
        right: -20%;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}
  @keyframes fadeInRight1 {
    0% {
        opacity: 0;
        right: -20%;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
