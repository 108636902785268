.App {
  text-align: center;
}
.footer-div h6 a{
  color: #21252a!important;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  max-height: 585px;
  padding: 20px;
  color: white;
  top:-50px;
  margin-bottom: -50px;

}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.homepage img{
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 40px;
  top:4px

}
.video_react div{
  top: -10%;
  position: absolute;
  left: 0%;
  z-index: 999;
  height: 120%!important;
}

.allnews{
  
  text-align: left;
  font-weight: 600;
  padding: 2%;
    margin-left: 5%;
}

.allnews img {
  width: 15px;

  margin-bottom: 5px;
  margin-left: 4px;
}

.allnews a{
  color: black
}

.nes:hover{
  color:black!important;
  text-decoration: none!important

}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media only screen and (min-width: 1367px) {
  .rehomehead .reheader {
    margin-bottom: 670px;
}
.pgallery .slide img{
  height: 700px;

}
.pgallery{
  height: 700px;
}
.play_text {

    top: 48.25%!important;
  
  }
  .App-header{
    max-height: 750px;

}

.video_react iframe {

  top: -20%!important;
 
  height: 148%!important;
}




.compass{
  top: 2%!important;
}
.main_content {
  width: 100vw!important;
}
}

@media only screen and (min-width: 768px) and (max-width: 960px) {

.photo-gallery-row img {
  width: 97.5%!important;
}

.mask {
  width: 90.2%!important;

  left: 3.5%!important;    
}
}


@media only screen and (min-width: 961px) and (max-width: 1024px) {
  .tab_content{

    padding-top: 10%!important;
    margin-top: -15%;

}

.mask {
  width: 89.2%!important;
  
}
.compass {
  top: -8%!important;
}
.play_text {
  font-size: 18px !important;
  right: 6%!important;
}

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
 



  @keyframes fadeInRight {
      
    0% {    

        opacity: 0;
    }
 100% {    

        opacity: 1;
    }
}

.closeb {
    
  font-size: 70px!important;
  
}
  .App-header{
    max-height: 350px;

}

.video_react iframe{
  padding-left: 0px

}

.overlay_list {

  font-size: 31px!important;
    margin: 42% 30%!important;}

  .nav {

    display: none!important;}

  .main_content{
    
    margin: 0 4vw;
    padding-top: 0px
  }  

  .tab_content{

    padding-top: 10%;
    margin-top: -15%

}

.tabup{
  padding: 3%;
  display: flex!important;
}
/* .slick-list{
  padding-top: 1.5%!important
} */

.video_text{
  bottom: 5%;
  position: absolute;
}

.play_text {
  font-size: 18px;}

}

@media only screen and (max-width: 767px) {
  .closeb {
    
    font-size: 45px!important;
    
}

  
  
  .main-content-head {
   
    padding-top: 5%;


}

.App-header{
  max-height: 200px;
  padding: 0px;

}

.video_react iframe{
padding-left: 0px

}

.overlay_list {
  font-size: 19px!important;
  margin: 70% 20%!important;
}

.nav {

  display: none!important;}

.main_content{
  
  margin: -5% 4vw;
}  

.tab_content{
  padding-top: 0px;
  
  

}
.tabup div {
  font-size: 12px!important;
}


.tabup{
  padding: 3%;
  display: flex!important;
}

/* .slick-list{
  padding-top: 1.5%!important
} */
.play_text {
  top: 44.23%!important;
    z-index: 1000!important;
    right: 12% !important;
    width: 21.25%!important;
    font-size: 14px !important;
}


}

.navbar a{
 
  padding: 1%
}