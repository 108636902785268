

.gallery_thumb{
    display: flex;
    flex-wrap: wrap;
}

.gallery_thumb div{
    padding: 0

}

.prev-next-buttonb{
    font-size: 30px;
    font-weight: bold;
    display: flex;
    width: 100vw
}

.prev-next-buttonb a{
    margin: auto;
    
}

.prev-next-buttonb div{
    margin: auto;
    
}

.prev-next-buttonb a{
cursor: pointer;    
}

.imagepopup{
    display: flex;
    z-index: 1020;
    top: 0;
    left: 0;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100%;
    color: #fff;
    background-color: #000000b3;
}

.closeb {
    color: white;
    position: absolute;
    top: 1%;
    right: 3%;
    font-size: 35px;
    font-weight: bold;
    z-index: 2000;
}

.cursor{
    cursor: pointer;
}
