body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
@media only screen and (min-width: 1400px) {
  .App .rehomehead .reheader {
    margin-bottom: 670px;
}
.App .pgallery .slide img{
  height: 700px;

}
.App .pgallery{
  height: 700px;
}
}